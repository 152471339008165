import React from 'react';
import './footer.sass';

export default function Footer() {
  return (
    <footer>
      <div className="footer_info">
        Farlands Team © 2024
      </div>
    </footer>
  );
}